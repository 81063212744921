import { Component } from 'react'

import { provideLocalizationService, registerForLocalization } from '@progress/kendo-react-intl/dist/es/intlUtils'

// export const Localize = (message: any): any => {
//     return provideLocalizationService(message).toLanguageString(message, message)
// }
// registerForLocalization(Localize)

export class Localize extends Component<any> {
  public render(): any {
    const { message } = this.props
    return provideLocalizationService(this).toLanguageString(message, message.default)
  }
}

registerForLocalization(Localize)
