import React from 'react';
import { commonSettings } from '../../settings';
import { SectionTreeListComponent } from './SectionTreeList';
export const DataProtectionContent = ({ mainClassName, messages }) => {
    const dataProtectionInfo = commonSettings.dataProtection;
    const ratiodataDetails = commonSettings.impressum;
    const dataProtectionText = messages.dataProtection.sections;
    const textBeforeCompanyDetails = {
        I: {
            title: dataProtectionText.I.title,
            1: dataProtectionText.I['1'],
            2: dataProtectionText.I['2'],
        },
    };
    const textAfterCompanyDetails = { ...dataProtectionText };
    textAfterCompanyDetails.I = {
        title: undefined,
        3: dataProtectionText.I['3'],
        4: dataProtectionText.I['4'],
    };
    return (React.createElement("main", { className: `page page-data-protection ${mainClassName ?? ''}` },
        React.createElement("div", { className: 'container-fluid' },
            React.createElement("h1", { className: 'page-title' },
                messages.dataProtection.privacyPolicy,
                " ",
                messages.appName),
            React.createElement("div", { className: 'page-content' },
                React.createElement("div", { className: 'data-protection-info' },
                    React.createElement(SectionTreeListComponent, { list: textBeforeCompanyDetails, minLevelToIndent: 1 }),
                    React.createElement("p", { className: 'grey-text' },
                        messages.dataProtection.dataProtectionResponsible,
                        React.createElement("br", null),
                        ratiodataDetails.companyName,
                        React.createElement("br", null),
                        ratiodataDetails.companyAddress.address,
                        React.createElement("br", null),
                        `${ratiodataDetails.companyAddress.zip} ${ratiodataDetails.companyAddress.city}`,
                        React.createElement("br", null),
                        messages.germany,
                        React.createElement("br", null),
                        React.createElement("br", null),
                        messages.rdPhone,
                        " ",
                        ratiodataDetails.companyPhone,
                        React.createElement("br", null),
                        messages.rdEmail,
                        ' ',
                        React.createElement("a", { className: 'mail-link', href: `mailto:${dataProtectionInfo.email}` }, dataProtectionInfo.email),
                        React.createElement("br", null)),
                    React.createElement(SectionTreeListComponent, { list: textAfterCompanyDetails, minLevelToIndent: 1 }))))));
};
