import { IStoreHasError } from '@rollout/common/lib/models/apiTypes'
import { initialEmployeeProject } from '@rollout/common/lib/models/project'

import { getIsAuth } from 'helpers'
import { IEmployee } from 'models/employee'
import { ILocale } from 'models/locale'
import { settings } from 'settings'
import { IAuth, IState, IStoreContext } from './types'

const localeInitialState: ILocale = {
  code: 'de',
  language: 'de-DE',
}

const authInitialState: IAuth = {
  name: '',
  email: '',
}

const employeeInitialState: IEmployee = {
  firstName: '',
  lastName: '',
  mobile: '',
  rowVersion: '',
  userName: '',
  email: '',
}

const activeDevice: string = null
const employeeDeadlinePassed = false

export const initialState: IState = {
  auth: authInitialState,
  locale: localeInitialState,
  isLoading: 0,
  isSuccess: true,
  hasError: {
    shouldRetry: false,
    message: '',
  } as IStoreHasError,
  employee: employeeInitialState,
  devices: [],
  projects: undefined,
  project: initialEmployeeProject,
  activeDevice,
  employeeDeadlinePassed,
  isAuth: getIsAuth(settings.isAuthCookieKey),
  isSupportVisible: true,
  isMobileLinkVisible: undefined,
  endUserUrl: undefined,
}

export const initialStoreContext: IStoreContext = {
  dispatch: undefined,
  messages: undefined,
  state: initialState,
}
