import dayjs from 'dayjs';
import { commonSettings } from '../settings';
export const formatDate = (date, format) => {
    if (date) {
        format = format ? format : commonSettings.dateFormat;
        const formatedDate = dayjs(date).format(format);
        return formatedDate;
    }
    return '';
};
export const formatToDate = (date) => {
    return dayjs(date);
};
export const transformDateToServerFormat = (date) => {
    return formatDate(date, commonSettings.serverDateFormat);
};
export const lessThanToday = (date) => {
    if (date) {
        return getDateDifferance(dayjs(date), dayjs().startOf('day')) < 0;
    }
    return false;
};
export const lessThanOrEqualToday = (date) => {
    if (date) {
        return getDateDifferance(dayjs(date), dayjs().startOf('day')) <= 0;
    }
    return false;
};
const getDateDifferance = (firstDate, secondDate) => {
    return firstDate.diff(secondDate, 'days');
};
