import React, { createContext, useContext, useCallback } from 'react'
import { AxiosRequestConfig, AxiosResponse, AxiosInstance } from 'axios'

import {
  methods,
  IApiContext,
  IFetchAPIProps,
  IResponseServerMessage,
  MessageType,
  IErrorMessageThrown,
  IStoreHasError,
} from '@rollout/common/lib/models/apiTypes'
import { useAxiosInstanceWithTokenInterceptors } from '@rollout/common/lib/hooks/axiosWithInterceptors'
import { ToasterContext } from '@rollout/common/lib/components/Toaster/Context/ToasterContext'

import { handleServerErrors } from 'helpers'
import { StateContext } from 'store'
import { settings } from 'settings'

const contextInitialState: IApiContext = {
  fetchAPI: null,
  callEndpoint: null,
  callEndpointWithToasterMessages: null,
  callEndpointWithLoading: null,
  callEndpointWithLoadingAndToasterMessages: null,
}

export const ApiContext = createContext(contextInitialState)

export const ApiProvider = (props: any) => {
  const { messages, dispatch } = useContext(StateContext)
  const [, setToasterMessages] = useContext(ToasterContext)
  const axiosInstance: AxiosInstance = useAxiosInstanceWithTokenInterceptors({
    isAuthCookieKey: settings.isAuthCookieKey,
    noTokenNeededOn: settings.noTokenNeededOn,
    logout: () =>
      dispatch({
        type: 'LOGOUT',
        payload: null,
      }),
  })

  // helper function for making requests with Axios, providing some default headers and enabling customization of the request with optional parameters.
  const fetchAPI = useCallback(
    (url: any, method: methods, body?: any, overrideConfig?: any, overrideHeaders?: any): Promise<AxiosResponse> => {
      return new Promise((resolve, reject) => {
        const requestConfig: AxiosRequestConfig = {
          method,
          url,
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            ...overrideHeaders,
          },
          withCredentials: true,
          ...overrideConfig,
        }
        if (body instanceof FormData) {
          requestConfig.data = body
        } else if (method !== 'GET' || body !== null) {
          requestConfig.data =
            (method === 'POST' || method === 'PUT' || method === 'DELETE') && body !== undefined
              ? JSON.stringify(body)
              : undefined
        }

        const promise = axiosInstance.request<IFetchAPIProps>(requestConfig)
        promise.then(resolve, reject)
      })
    },
    [axiosInstance]
  )

  // wrapper method around the 'fetchAPI' method that adds additional handling for processing the response and errors. 
  const callEndpoint = useCallback(
    (
      endpoint: string,
      method: methods,
      body?: any,
      overrideConfig?: any,
      overrideHeaders?: any
    ): Promise<IFetchAPIProps> => {
      return new Promise<IFetchAPIProps>((resolve, reject) => {
        const fetchAPIPromise = fetchAPI(endpoint, method, body, overrideConfig, overrideHeaders)
          .then((res: AxiosResponse) => {
            return res.data
          })
          .then((result: IFetchAPIProps) => {
            if (result && result.messages) {
              return {
                ...result,
                messages: handleServerErrors(result.messages as IResponseServerMessage[], messages, method, endpoint),
              }
            }
            return result
          })
          .catch((error: any) => {
            if (error) {
              if (error.message === 'LOGOUT') {
                // disregard forced logout
                return error
              }
              if (error.message === 'termsAndConditions') {
                throw mapToErrorMessages(3)
              }
              if (error.code === 'ECONNABORTED') {
                // handle request timeout
                throw mapToErrorMessages(0, messages.requestTimeoutError)
              }
              if (error.toJSON().message === 'Network Error') {
                throw mapToErrorMessages(800)
              }
              throw error
            }
            throw error
          })
          .catch((error: any) => {
            if (error && error.response && error.response.data) {
              const response = error.response.data
              if (
                (response.messages && response.messages.length) ||
                (response.validationMessages && response.validationMessages.length)
              ) {
                // return messages to be displayed
                return {
                  ...response,
                  messages: handleServerErrors(response.messages, messages, method, endpoint),
                }
              }
            }
            // when no specific error message from server
            dispatchError(error)
            return error
          })
        fetchAPIPromise.then(resolve, reject)
      })
    },
    [fetchAPI, dispatch, messages]
  )

  const callEndpointWithToasterMessages = useCallback(
    (endpoint: string, method: methods, body?: any): Promise<IFetchAPIProps> => {
      return new Promise((resolve, reject) => {
        const fetchAPIPromise = callEndpoint(endpoint, method, body).then((result: IFetchAPIProps) => {
          if (result && result.isSuccess === true) {
            return result
          }
          if (result && result.messages) {
            setToasterMessages(result.messages)
            return result
          }
          return result
        })
        fetchAPIPromise.then(resolve, reject)
      })
    },
    [callEndpoint, setToasterMessages]
  )

  const callEndpointWithLoading = useCallback(
    (
      endpoint: string,
      method: methods,
      body?: any,
      overrideConfig?: any,
      overrideHeaders?: any
    ): Promise<IFetchAPIProps> => {
      return new Promise((resolve, reject) => {
        dispatch({
          type: 'IS_LOADING',
          payload: true,
        })
        const fetchAPIPromise = callEndpoint(endpoint, method, body, overrideConfig, overrideHeaders).then(
          (result: IFetchAPIProps) => {
            dispatch({
              type: 'IS_LOADING',
              payload: false,
            })
            return result
          }
        )
        fetchAPIPromise.then(resolve, reject)
      })
    },
    [callEndpoint, dispatch]
  )

  const callEndpointWithLoadingAndToasterMessages = useCallback(
    (endpoint: string, method: methods, body?: any): Promise<IFetchAPIProps> => {
      return new Promise((resolve, reject) => {
        dispatch({
          type: 'IS_LOADING',
          payload: true,
        })
        const fetchAPIPromise = callEndpointWithToasterMessages(endpoint, method, body).then(
          (result: IFetchAPIProps) => {
            dispatch({
              type: 'IS_LOADING',
              payload: false,
            })
            return result
          }
        )
        fetchAPIPromise.then(resolve, reject)
      })
    },
    [callEndpointWithToasterMessages, dispatch]
  )

  const mapToErrorMessages = (identifier: number, argument?: string): IErrorMessageThrown => {
    return {
      response: {
        data: {
          isSuccess: false,
          hasErrors: true,
          hasWarnings: false,
          hasValidationErrors: false,
          messages: [
            {
              identifier,
              arguments: argument ? [argument] : undefined,
              messageType: MessageType.ValidationError,
            }
          ],
        },
      },
    }
  }

  const dispatchError = (error: any) => {
    if (error?.response?.status === 429) {
      //handle request limit error
      dispatch({
        type: 'HAS_ERROR',
        payload: {
          shouldRetry: false,
          message: messages.validationErrors[801]
        } as IStoreHasError
      })
      return
    }
    dispatch({
      type: 'HAS_ERROR',
      payload: {
        shouldRetry: true,
        message: messages.internalServerErrorPart1
      } as IStoreHasError
    })
  }

  const apiStateToPass: IApiContext = {
    callEndpointWithLoadingAndToasterMessages,
    callEndpointWithToasterMessages,
    callEndpointWithLoading,
    callEndpoint,
    fetchAPI,
  }

  return <ApiContext.Provider value={apiStateToPass}>{props.children}</ApiContext.Provider>
}
