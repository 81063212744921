import { useEffect, useState } from 'react';
const prefixes = ['webkit', 'moz', 'ms', 'o'];
const getHiddenProp = () => {
    // if 'hidden' is natively supported just return it
    if ('hidden' in document) {
        return 'hidden';
    }
    // otherwise loop over all the known prefixes until we find one
    for (const prefix of prefixes) {
        if ((prefix + 'Hidden') in document) {
            return prefix + 'Hidden';
        }
    }
    // otherwise it's not supported
    return null;
};
const isHidden = () => {
    const prop = getHiddenProp();
    if (!prop) {
        return false;
    }
    return document[prop];
};
export const usePageVisibility = () => {
    const [isVisible, setIsVisible] = useState(isHidden());
    const onVisibilityChange = () => {
        setIsVisible(!isHidden());
    };
    useEffect(() => {
        // use the property name to generate the prefixed event name
        const visibilityProp = getHiddenProp();
        let eventName = null;
        if (visibilityProp) {
            eventName = visibilityProp.replace(/[H|h]idden/, '') + 'visibilitychange';
            document.addEventListener(eventName, onVisibilityChange, false);
        }
        return () => {
            if (eventName) {
                window.removeEventListener(eventName, onVisibilityChange);
            }
        };
    }, []);
    return isVisible;
};
