import React from 'react';
import { commonSettings } from '../../settings';
;
export const ImpressumContent = ({ mainClassName, messages }) => {
    const impressumData = commonSettings.impressum;
    const comapanyUrl = `https://${impressumData.companyHomepage}`;
    return (React.createElement("main", { className: `page page-impressum ${mainClassName ?? ''}` },
        React.createElement("div", { className: 'container-fluid' },
            React.createElement("h1", { className: 'page-title' }, messages.impressum.impressum),
            React.createElement("div", { className: 'page-content' },
                React.createElement("h2", { className: 'page-subtitle' }, messages.impressum.editor),
                React.createElement("h4", null, impressumData.companyName),
                React.createElement("div", { className: 'impressum-info' },
                    React.createElement("p", null,
                        messages.rdPhone,
                        " ",
                        impressumData.companyPhone,
                        React.createElement("br", null),
                        messages.rdEmail,
                        ' ',
                        React.createElement("a", { className: 'mail-link', href: `mailto:${impressumData.companyEmail}` }, impressumData.companyEmail),
                        React.createElement("br", null),
                        messages.impressum.homepage,
                        ': ',
                        React.createElement("a", { className: 'mail-link', href: comapanyUrl, target: "_blank" }, impressumData.companyHomepage)),
                    React.createElement("p", null,
                        messages.impressum.boardOfDirectors,
                        ": ",
                        impressumData.companyBoardOfDirectors.replace('${boardSpokesmanTranslation}', messages.impressum.boardSpokesman)),
                    React.createElement("p", null,
                        messages.impressum.chairman,
                        ": ",
                        impressumData.chairman),
                    React.createElement("p", null,
                        messages.impressum.companyOffice,
                        ": ",
                        impressumData.companyOffice,
                        React.createElement("br", null),
                        messages.impressum.businessAddress,
                        ":",
                        ' ',
                        `${impressumData.companyAddress.address}, ${impressumData.companyAddress.zip} ${impressumData.companyAddress.city}`,
                        React.createElement("br", null),
                        messages.impressum.commercialRegister,
                        ": ",
                        impressumData.commercialRegister,
                        React.createElement("br", null),
                        messages.impressum.taxIdNumber,
                        ": ",
                        impressumData.taxIdNumber),
                    React.createElement("p", null, impressumData.companyGroupInfo))))));
};
