import React from 'react';
// import './connection.scss'
export const Connection = (props) => {
    const { errorMessage, refreshMessage } = props;
    return (React.createElement("main", { className: 'container connection-error-page' },
        React.createElement("section", { className: 'page-body' },
            React.createElement("div", { className: 'error-message alert alert-danger' },
                errorMessage,
                refreshMessage ? (React.createElement("button", { className: 'refresh-button', onClick: () => window.location.reload() },
                    React.createElement("strong", null,
                        React.createElement("u", null, refreshMessage)))) : React.createElement(React.Fragment, null)))));
};
