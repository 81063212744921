import React, { useContext, useEffect } from 'react'
import { isMobileOnly } from 'react-device-detect'

import { IOpenMobileAppProps } from './types'
import { StateContext } from '../../store'
import { settings } from '../../settings'

import rolloutAppIcon from '../../assets/rolloutAppIcon.svg'
import './openMobileApp.scss'

const OpenMobileApp = (props: IOpenMobileAppProps) => {
  const { dispatch, messages, state } = useContext(StateContext)

  useEffect(() => {
    /*
        If initially undefined in store/intialState.ts,
          set the link visible only if was not already displayed and closed
        If initially false in store/initialState.ts,
          this functionality is not used in the app
     */
    if (state.isMobileLinkVisible === undefined) {
      dispatch({
        type: 'MOBILE_LINK_VISIBLE',
        payload: isMobileOnly,
      })
    }
  }, [])

  const hideLink = () => {
    dispatch({
      type: 'MOBILE_LINK_VISIBLE',
      payload: false,
    })
  }

  if (!state.isMobileLinkVisible) {
    return null
  }

  return (
    <div className='open-mobile-app-header'>
      <div className='app-details'>
        <span onClick={hideLink} className='rd-icon-invalid app-close' />
        <img className='app-logo' alt={messages.appName} width={42} height={42} src={rolloutAppIcon} />
        <label className='app-name'>{settings.mobileAppName}</label>
      </div>
      <label onClick={props.action} className='open-app-action'>
        {props.actionTitle}
      </label>
    </div>
  )
}
export default OpenMobileApp
