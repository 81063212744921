import { FindAndReplace } from './FindAndReplace';
const identifierToMessage = (message, messages, method, endpoint) => {
    const messageId = message.identifier.toString();
    // check if custom error message was defined for this endpoint
    const messageForIdentifier = messages.validationErrors[`${method}:${endpoint}`]
        && messages.validationErrors[`${method}:${endpoint}`][messageId]
        ? messages.validationErrors[`${method}:${endpoint}`][messageId]
        : messages.validationErrors[messageId];
    if (messageForIdentifier) {
        return FindAndReplace(messageForIdentifier, message.arguments, messages.empty);
    }
    return `Error identifier: ${messageId}`;
};
const normalizeEndpoint = (endpoint) => {
    // replace IDs from endpoint with '*'
    if (endpoint) {
        return endpoint.replace(/(\/|=)([0-9a-fA-F]{8}[-]?(?:[0-9a-fA-F]{4}[-]?){3}[0-9a-fA-F]{12})(\/|\s|\|&|\\?)/g, '$1*$3'); // second group is for the guid format
    }
    return endpoint;
};
export const handleServerErrors = (validationErrors, messages, method, endpoint) => {
    if (validationErrors && validationErrors.length) {
        const normalizedEndpoint = normalizeEndpoint(endpoint);
        return validationErrors.map((message) => {
            return {
                message: identifierToMessage(message, messages, method, normalizedEndpoint),
                type: message.messageType,
                identifier: message.identifier,
                arguments: message.arguments
            };
        });
    }
    return [];
};
export const mapDisplayServerMessagesToObject = (errors) => {
    let errorsObject = {};
    errors.forEach((error) => {
        errorsObject[error.identifier] = error;
    });
    return errorsObject;
};
