import React, { Component, ReactNode } from 'react'

export interface IErrorBoundary {
  children?: ReactNode
}

class ErrorBoundary extends Component<IErrorBoundary, any> {
  constructor(props: IErrorBoundary) {
    super(props)
    this.state = {
      error: null,
      errorInfo: null,
    }
  }

  public componentDidCatch(error: any, errorInfo: any) {
    this.setState({
      error,
      errorInfo,
    })
  }

  public render() {
    const { error, errorInfo } = this.state
    if (errorInfo) {
      return (
        <div>
          <h2>Something went wrong.</h2>
          <details style={{ whiteSpace: 'pre-wrap' }}>
            {error && error.toString()}
            <br />
            {errorInfo.componentStack}
          </details>
        </div>
      )
    }
    return this.props.children
  }
}

export default ErrorBoundary
