import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
export const AuthRoute = ({ redirectPath, isAuth, isPublicRoute, accessDenied }) => {
    if (accessDenied) {
        return React.createElement(Navigate, { to: '/access-denied', replace: true });
    }
    if (isPublicRoute) {
        if (isAuth) {
            return React.createElement(Navigate, { to: redirectPath, replace: true });
        }
        return React.createElement(Outlet, null);
    }
    else {
        if (!isAuth) {
            return React.createElement(Navigate, { to: redirectPath, replace: true });
        }
        return React.createElement(Outlet, null);
    }
};
