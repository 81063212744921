import React, { useState, useEffect, useCallback } from 'react';
import { handleServerErrors } from '../../../helpers/HandleErrors';
const ToasterContext = React.createContext([[], () => []]);
const ToasterProvider = (props) => {
    const { messages, isAuth, timeout, children } = props;
    const [toasterState, setToasterState] = useState([]);
    const removeToaster = useCallback((keys) => {
        setToasterState((prevState) => {
            const newMessages = prevState.filter((message) => {
                return keys.indexOf(message.key) === -1;
            });
            return newMessages;
        });
    }, []);
    const setToaster = useCallback((values) => {
        const keys = [];
        if (values) {
            const newMessages = values.map((value, index) => {
                if (!value.key) {
                    const key = Date.now() + index;
                    keys.push(key);
                    value.key = key;
                }
                return value;
            });
            if (keys.length) {
                setTimeout(() => {
                    removeToaster(keys);
                }, timeout);
            }
            setToasterState((prevState) => {
                //do not display the same message twice at the same time
                const allToasterMessages = [...prevState, ...newMessages];
                const uniqueToasterMessagesObject = {};
                allToasterMessages.forEach((element) => {
                    const key = element.type + '-' + element.message;
                    uniqueToasterMessagesObject[key] = element;
                });
                return uniqueToasterMessagesObject ? Object.values(uniqueToasterMessagesObject) : [];
            });
        }
    }, [removeToaster]);
    useEffect(() => {
        const initToaster = localStorage.getItem('initToaster');
        if (initToaster) {
            const initToasterArray = JSON.parse(initToaster);
            if (initToasterArray) {
                const initToasterMessages = handleServerErrors(initToasterArray, messages, null, null);
                setToaster(initToasterMessages);
            }
        }
        localStorage.removeItem('initToaster');
    }, [messages, setToaster, isAuth]);
    return (React.createElement(ToasterContext.Provider, { value: [toasterState, setToaster, removeToaster] }, children));
};
export { ToasterContext, ToasterProvider };
