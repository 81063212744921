export const clearLocalStorage = (initToaster) => {
    localStorage.clear();
    sessionStorage.clear();
    if (initToaster) {
        localStorage.setItem('initToaster', initToaster);
    }
};
export const removeItemFromStorage = (key) => {
    !!key && localStorage.removeItem(key);
};
export const setItemInStorage = (key, value) => {
    !!key && !!value && localStorage.setItem(key, value);
};
export const getItemFromStorage = (key) => {
    if (!!key) {
        return localStorage.getItem(key);
    }
    return '';
};
export const getIsAuth = (key) => {
    const isAuthCookieValue = getCookieValue(key);
    return isAuthCookieValue?.toLowerCase() === 'true';
};
export const getCookieValue = (cookieName) => {
    return document.cookie
        ?.split('; ')
        ?.find(row => row.startsWith(`${cookieName}=`))
        ?.split('=')[1];
};
