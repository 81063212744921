import React, { Fragment, useContext } from 'react'
import { ImpressumContent } from '@rollout/common/lib/blocks/ImpressumContent'

import { StateContext } from 'store'
import { settings } from 'settings'
import { Footer } from 'components/Footer'
import OpenMobileApp from 'components/OpenMobileApp'

import '@rollout/common/src/scss/impressum.scss'

export const Impressum = () => {
  const { state, messages } = useContext(StateContext)

  const openMobileApp = (): void => {
    window.location.href = `${settings.mobileAppUrlScheme}`
  }

  return (
    <Fragment>
      <OpenMobileApp actionTitle={messages.useApp} action={openMobileApp} />
      <ImpressumContent
        mainClassName={state.isMobileLinkVisible ? 'mobile-link-margin' : ''}
        messages={{
          rdPhone: messages.rdPhone,
          rdEmail: messages.rdEmail,
          impressum: {
            impressum: messages.impressum.impressum,
            homepage: messages.impressum.homepage,
            editor: messages.impressum.editor,
            boardOfDirectors: messages.impressum.boardOfDirectors,
            boardSpokesman: messages.impressum.boardSpokesman,
            chairman: messages.impressum.chairman,
            companyOffice: messages.impressum.companyOffice,
            businessAddress: messages.impressum.businessAddress,
            commercialRegister: messages.impressum.commercialRegister,
            taxIdNumber: messages.impressum.taxIdNumber,
          },
        }}
      />
      <Footer />
    </Fragment>
  )
}
