import { commonSettings, ENV } from '@rollout/common/lib/settings'

// prettier-ignore
// eslint-disable-next-line no-useless-escape
const specialCharacter = '~!@#\\$%\\^&\\*_\\-\\+=`\\|\\\\\\(\\)\\{\\}\\[\\]:;"\'<>,\\.\\?\\/' // special characters escaped
const atLeastOne = '?=.*'
const lowercaseLetter = 'a-z'
const uppercaseLetter = 'A-Z'
const digit = '0-9'
const appPrefix = 'eu-'

export const settings = {
  ...commonSettings,
  API_URL: 'http://127.0.0.1:8081',
  passwordPattern: `^(${atLeastOne}[${lowercaseLetter}])(${atLeastOne}[${uppercaseLetter}])(${atLeastOne}[${specialCharacter}])(${atLeastOne}[${digit}])[${lowercaseLetter}${uppercaseLetter}${digit}${specialCharacter}]{9,128}$`,
  appPrefix,
  isAuthCookieKey: `${appPrefix}${ENV.REACT_APP_SHORT_ENV}.isAuth`,
  noTokenNeededOn: [
    'cookies-authorization/login',
    'users/reset-password',
    'users/password-recovery-email',
    'users/validate-account-confirmation-code',
    'users/validate-password-recovery-code',
    'users/confirm-account',
    'users/new-account-confirmation-code',
    'home/version',
  ],
  rolloutApp: 'Rollout App',
  mobileAppName: 'RolloutApp',
  mobileAppUrlScheme: 'rolloutapp://',
  displayCompleteWorkTimeout: 1000,
}

export const envSettings = { ...process.env }
