import React, { Fragment, useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router'
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'
import { ProjectType } from '@rollout/common/lib/models/project'
import { IDisplayServerMessage } from '@rollout/common/lib/models/apiTypes'
import { ToasterContext } from '@rollout/common/lib/components/Toaster/Context/ToasterContext'

import { StateContext } from 'store'
import { ApiContext } from 'store/ApiContext'

import logoImageSrc from 'assets/ratiodata-logo.svg'
import rolloutProjectIcon from 'assets/rollout-project.svg'
import assetcheckProjectIcon from 'assets/assetcheck-project.svg'
import './header.scss'

const MAX_PROJECT_NAME_LENGTH = 30

const Header = () => {
  const navigate = useNavigate()

  const { state, dispatch, messages } = useContext(StateContext)
  const { callEndpointWithLoading } = useContext(ApiContext)
  const [, setToasterMessages] = useContext(ToasterContext)

  const [isUserDropdownOpen, setIsUserDropdownOpen] = useState<boolean>(false)
  const [isProjectSelectorOpen, setIsProjectSelectorOpen] = useState<boolean>(false)
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false)
  const [selectedProjectId, setSelectedProjectId] = useState<string>(null)
  const [isProjectSelectorDisplayed, setIsProjectSelectorDisplayed] = useState<boolean>(false)

  const projectTypeObj = {
    Rollout: {
      icon: rolloutProjectIcon,
      title: messages.rollout,
    },
    AssetCheck: {
      icon: assetcheckProjectIcon,
      title: messages.assetCheck,
    },
  }

  useEffect(() => {
    fetchPersonalInfo()
  }, [])

  useEffect(() => {
    setIsProjectSelectorDisplayed(!!state.projects && state.projects.length > 1)
  }, [state.projects])

  useEffect(() => {
    setSelectedProjectId(state.project?.employeeProjectId)
  }, [state.project?.employeeProjectId])

  const fetchPersonalInfo = async () => {
    const result = await callEndpointWithLoading('users', 'GET')
    if (result && result.isSuccess === true) {
      const projects = result.entity?.projects

      dispatch({
        type: 'END_USER_URL',
        payload: result.entity.endUserUrl,
      })
      dispatch({
        type: 'FETCH_PROJECTS',
        payload: projects,
      })
      dispatch({
        type: 'FETCH_EMPLOYEE',
        payload: result.entity.employee,
      })
    } else if (result?.messages?.length) {
      setToasterMessages(result.messages as IDisplayServerMessage[])
    }
  }

  const toggleUserDropdown = () => {
    setIsUserDropdownOpen((prevState) => !prevState)
  }

  const toggleProjectSelector = () => {
    setIsProjectSelectorOpen((prevState) => !prevState)
  }

  const toggleMenu = () => {
    setIsMenuOpen((prevState) => !prevState)
  }

  const onProjectSelected = (item) => {
    setSelectedProjectId(item.employeeProjectId)
    navigate(`/${messages.routes.dashboard}/${item.employeeProjectId}`)
  }

  const onLogoClicked = () => {
    window.location.assign(
      isProjectSelectorDisplayed || !selectedProjectId ? '/' : `/${messages.routes.dashboard}/${selectedProjectId}`
    )
  }

  const renderProjectItems = () => {
    return (
      <Fragment>
        {state.projects?.map((item) => {
          const projectType = projectTypeObj[ProjectType[item.projectType]]
          return (
            <DropdownItem
              onClick={() => onProjectSelected(item)}
              key={item.employeeProjectId}
              className={`menu-item ${item.employeeProjectId === selectedProjectId ? 'selected' : ''}`}
            >
              <img
                className='project-type-icon'
                alt={projectType.title}
                width={24}
                height={24}
                src={projectType.icon}
              />
              <span className='project-type'>{projectType.title}:</span>
              {item.name.length > MAX_PROJECT_NAME_LENGTH - 3
                ? `${item.name.substring(0, MAX_PROJECT_NAME_LENGTH - 3)}...`
                : `${item.name}`}
            </DropdownItem>
          )
        })}
      </Fragment>
    )
  }

  return (
    <Fragment>
      <div className='row no-gutters'>
        <div className={`col-12 d-sm-none menu-wrapper fixed ${state.isMobileLinkVisible ? 'mobile-link-margin' : ''}`}>
          <Dropdown isOpen={isMenuOpen} toggle={toggleMenu} direction='down' className='menu'>
            <DropdownToggle className='menu-toggle' tag='div'>
              <img className='app-logo' src={logoImageSrc} alt={messages.appName} height='24' />
              <span className={`rd-icon ${isMenuOpen ? 'rd-icon-invalid' : 'rd-icon-menu'} rd-icon-24`} />
            </DropdownToggle>
            <DropdownMenu>
              {isProjectSelectorDisplayed && renderProjectItems()}
              {isProjectSelectorDisplayed && <div className='divider' />}
              <Link className='menu-item logout' to={`/${messages.routes.logout}`}>
                <b>{messages.logout}</b>
              </Link>
            </DropdownMenu>
          </Dropdown>
          {isMenuOpen && <div className='overlay-mask' />}
        </div>
      </div>
      <header className={`header ${state.isMobileLinkVisible ? 'mobile-link-margin' : ''}`}>
        <div className='container-fluid'>
          <div className='row'>
            <div className={`col-6 ${isProjectSelectorDisplayed ? 'col-sm-4' : ''} d-none d-sm-block`}>
              <div className='app-name' onClick={onLogoClicked}>
                <img className='app-logo' src={logoImageSrc} alt={messages.appName} height='24' />
                <span className='app-logo-text'>{messages.appName}</span>
              </div>
            </div>
            {isProjectSelectorDisplayed && (
              <div className='col-sm-4 d-none d-sm-flex project-selector-wrapper'>
                <Dropdown
                  isOpen={isProjectSelectorOpen}
                  toggle={toggleProjectSelector}
                  direction='down'
                  className='project-selector'
                >
                  <DropdownToggle caret>{messages.projects}</DropdownToggle>
                  <DropdownMenu>{renderProjectItems()}</DropdownMenu>
                </Dropdown>
              </div>
            )}
            <div className={`col-6 ${isProjectSelectorDisplayed ? 'col-sm-4' : ''} d-none d-sm-flex user-wrapper`}>
              <div className='user'>
                <div className='user-infobox'>
                  <div className='user-avatar'>
                    <span className='rd-icon rd-icon-user rd-icon-24' />
                  </div>
                  <div className='user-text'>
                    <Dropdown isOpen={isUserDropdownOpen} toggle={toggleUserDropdown} direction='down'>
                      <DropdownToggle caret>{state.auth.name}</DropdownToggle>
                      <DropdownMenu end>
                        <DropdownItem>
                          <Link className='dropdown-link no-text-decoration' to={`/${messages.routes.logout}`}>
                            {messages.logout}
                          </Link>
                        </DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </Fragment>
  )
}

export default Header
