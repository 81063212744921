import React, { useContext } from 'react';
import { Notification, NotificationGroup } from '@progress/kendo-react-notification';
import { ToasterContext } from './Context/ToasterContext';
// import './toaster.scss';
const NotificationType = (type) => {
    switch (type) {
        case 0: return 'info';
        case 1: return 'warning';
        case 2: return 'error';
        case 3: return 'error';
        case 4: return 'success';
        default: return 'none';
    }
};
export const Toaster = () => {
    const [messages, , removeMessages] = useContext(ToasterContext);
    return (React.createElement("div", null, !!messages &&
        React.createElement(NotificationGroup, { style: {
                alignItems: 'flex-start',
                flexWrap: 'wrap-reverse'
            } }, messages.map((item) => {
            return (React.createElement(Notification, { className: 'notification-wrapper', key: item.key, type: { style: NotificationType(item.type), icon: false }, closable: { className: 'rd-icon-close' }, onClose: () => removeMessages([item.key]) }, item.message));
        }))));
};
