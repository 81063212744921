export const FindAndReplace = (str, data, emptyMessage) => {
    return FindAndReplaceWithCustomFormat(str, data, emptyMessage, '{', '}');
};
export const FindAndReplaceWithCustomFormat = (str, data, emptyMessage, prefix, sufix) => {
    const unescapedPrefix = prefix.replace('\\', '');
    const unescapedSufix = sufix.replace('\\', '');
    if (data instanceof Array) {
        const regex = RegExp(`${prefix}\\d${sufix}`, 'g');
        return str.replace(regex, (i) => {
            return data[i.replace(unescapedPrefix, '').replace(unescapedSufix, '')];
        });
    }
    else if (data instanceof Object) {
        if (Object.keys(data).length === 0) {
            return str;
        }
        const regex = RegExp(`${prefix}([^}]+)${sufix}`, 'g');
        return str.replace(regex, (i) => {
            const key = i.replace(unescapedPrefix, '').replace(unescapedSufix, '');
            const keyData = safeGetValue(data, key);
            if (!keyData) {
                return emptyMessage;
            }
            return keyData;
        });
    }
    else if (!(data instanceof Array || data instanceof Object)) {
        return str;
    }
    return '';
};
export const safeGetValue = (object, propertyPath) => {
    let currentPosition = object;
    const propertyPathArray = propertyPath.split('.');
    for (let i = 0; i < propertyPathArray.length; i++) {
        const currentKey = propertyPathArray[i];
        if (currentPosition[currentKey] === undefined) {
            return undefined;
        }
        currentPosition = currentPosition[currentKey];
    }
    return currentPosition?.toString();
};
