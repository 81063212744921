import React from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { Store } from './store'
import ErrorBoundary from './helpers/ErrorBoundary'
import App from './App'
import * as serviceWorker from './serviceWorker'

const rootElement = document.getElementById('root')
const root = createRoot(rootElement)
root.render(
  <Store>
    <ErrorBoundary>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ErrorBoundary>
  </Store>
)

serviceWorker.unregister()
