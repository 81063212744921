import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router'

import { StateContext } from 'store'
import { settings } from 'settings'
import { ApiContext } from 'store/ApiContext'
import ratiodataLogoImageSrc from 'assets/ratiodata-logo.svg'

import './footer.scss'

const Footer = () => {
  const navigate = useNavigate()
  const { messages } = useContext(StateContext)
  const { callEndpoint } = useContext(ApiContext)
  const [version, setVersion] = useState<string>('')

  useEffect(() => {
    getVersion()
  }, [])

  const getVersion = async () => {
    const result = await callEndpoint('home/version', 'GET')
    if (result && result.isSuccess) {
      setVersion(result.entity)
    }
  }

  return (
    <footer className='footer'>
      <div className='app-logo-wrapper'>
        <img className='app-logo' src={ratiodataLogoImageSrc} alt={messages.appName} height='32' />
      </div>
      <div className='company-details-wrapper'>
        <button className='link' onClick={() => navigate(`/${messages.routes.impressum}`)}>
          {messages.impressum.impressum}
        </button>
        <button className='link' onClick={() => navigate(`/${messages.routes.dataProtection}`)}>
          {messages.dataProtection.dataProtection}
        </button>
        <span className='company-details-separator'>
          {'-'}
        </span>
        <span>
          {settings.impressum.companyGroupInfo}
        </span>
      </div>
      <div className='app-details-wrapper'>
        <span className='copyright'>
          {messages.footer.copyright}
        </span>
        {!!version && (
          <span>
            {messages.footer.version}: {version}
          </span>
        )}
      </div>
    </footer>
  )
}

export default Footer
