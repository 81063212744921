import React, { useContext, Suspense, lazy, useEffect, useCallback } from 'react'
import { Route, Routes } from 'react-router-dom'
import { Connection } from '@rollout/common/lib/components/Connection/Connection'
import { Loading } from '@rollout/common/lib/components/Loading/Loading'
import { Toaster } from '@rollout/common/lib/components/Toaster/Toaster'
import { ToasterProvider } from '@rollout/common/lib/components/Toaster/Context'
import { AuthRoute } from '@rollout/common/lib/helpers/AuthRoute'

import { LocalizationProvider } from '@progress/kendo-react-intl/dist/es/Localization/LocalizationProvider'
import { IntlProvider } from '@progress/kendo-react-intl/dist/es/Intl/IntlProvider'

import dayjs from 'dayjs'
import 'dayjs/locale/de'
import 'dayjs/locale/en-gb'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
dayjs.extend(isSameOrAfter)
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
dayjs.extend(isSameOrBefore)
import minMax from 'dayjs/plugin/minMax'
dayjs.extend(minMax)

import { loadCldr, setDateLocale } from '@rollout/common/lib/helpers/LoadLocales'
import { Header } from 'components/Header'
import { settings } from 'settings'
import { loadForLocale as loadMessages } from './i18n/messages'
import { StateContext } from './store'
import { ApiProvider } from './store/ApiContext'
import { Impressum } from 'pages/Impressum'
import { DataProtection } from 'pages/DataProtection'

import 'bootstrap/dist/css/bootstrap.css'
import '@rollout/common/src/scss/fonts/TitilliumWeb/titillium.scss'
import '@rollout/common/src/scss/fonts/RD-Icons/rd-icons.scss'
import '@rollout/common/src/scss/kendo.scss'
import '@rollout/common/src/scss//typography.scss'
import '@rollout/common/src/scss//buttons.scss'
import '@rollout/common/src/scss/modals.scss'
import '@rollout/common/src/scss/alerts.scss'
import '@rollout/common/src/scss/tooltip.scss'
import '@rollout/common/src/scss/link.scss'
import './scss/app.scss'
import '@rollout/common/src/scss/components.scss'
import '@rollout/common/src/scss/common.scss'

const Dashboard = lazy(() => import('./pages/Dashboard'))
const Login = lazy(() => import('./pages/Login'))
const Logout = lazy(() => import('./pages/Logout'))
const ConfirmAccount = lazy(() => import('./pages/ConfirmAccount'))
const RecoverAccount = lazy(() => import('./pages/RecoverAccount'))
const ResetPassword = lazy(() => import('./pages/ResetPassword'))
const Locale = lazy(() => import('./pages/Locale'))
const NotFound = lazy(() => import('./pages/NotFound'))

const App = (): JSX.Element => {
  const { state, messages } = useContext(StateContext)

  const onLoad = useCallback(async () => {
    loadCldr(state.locale.code)
    await loadMessages(state.locale.language)
    setDateLocale(state.locale.language)
  }, [state.locale])

  useEffect(() => {
    onLoad()
  }, [onLoad])

  return (
    <LocalizationProvider language={state.locale.language}>
      <IntlProvider locale={state.locale.code}>
        <ToasterProvider messages={messages} isAuth={state.isAuth} timeout={settings.toasterTimeout}>
          <ApiProvider>
            {state.isAuth && <Header />}
            {!!state.isLoading && <Loading />}
            {state.hasError?.message ? (
              <Connection
                errorMessage={state.hasError.message}
                refreshMessage={state.hasError.shouldRetry ? messages.internalServerErrorPart2 : null}
              />
            ) : (
              <Routes>
                <Route element={<AuthRoute isAuth={state.isAuth} redirectPath='/' isPublicRoute={false} />}>
                  <Route key={1} path={`/${messages.routes.logout}`} element={<Suspense fallback={<Loading />}> <Logout /> </Suspense>} />
                  <Route key={2} path={`/${messages.routes.dashboard}/*`} element={<Suspense fallback={<Loading />}> <Dashboard /> </Suspense>} />
                </Route>
                <Route element={<AuthRoute isAuth={state.isAuth} redirectPath={`/${messages.routes.dashboard}`} isPublicRoute={true}/>}>
                  <Route key={5} path={`/${messages.routes.confirmAccount}`} element={<Suspense fallback={<Loading />}> <ConfirmAccount /> </Suspense>} />
                  <Route key={6} path={`/${messages.routes.recoverAccount}`} element={<Suspense fallback={<Loading />}> <RecoverAccount /> </Suspense>} />
                  <Route  key={7} path={`/${messages.routes.resetPassword}`} element={<Suspense fallback={<Loading />}> <ResetPassword /> </Suspense>} />
                  <Route key={9} path='/' element={<Login />} />
                </Route>
                <Route key={3} path={`/${messages.routes.impressum}`} element={<Suspense fallback={<Loading />}> <Impressum /> </Suspense>} />
                <Route key={4} path={`/${messages.routes.dataProtection}`} element={<Suspense fallback={<Loading />}> <DataProtection /> </Suspense>} />
                <Route key={8} path='/lang/:id' element={<Suspense fallback={<Loading />}> <Locale /> </Suspense>} />
                <Route key={10} path='*' element={<Suspense fallback={<Loading />}> <NotFound /> </Suspense>} />
              </Routes>
            )}
            <Toaster />
          </ApiProvider>
        </ToasterProvider>
      </IntlProvider>
    </LocalizationProvider>
  )
}

export default App
