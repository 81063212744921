import dayjs from 'dayjs';
import { load } from '@progress/kendo-react-intl';
import likelySubtags from 'cldr-core/supplemental/likelySubtags.json';
import currencyData from 'cldr-core/supplemental/currencyData.json';
import weekData from 'cldr-core/supplemental/weekData.json';
import enNumbers from 'cldr-numbers-full/main/en/numbers.json';
import enCurrencies from 'cldr-numbers-full/main/en/currencies.json';
import enGregorian from 'cldr-dates-full/main/en/ca-gregorian.json';
import enDateFields from 'cldr-dates-full/main/en/dateFields.json';
import enTimeZoneNames from 'cldr-dates-full/main/en/timeZoneNames.json';
import deNumbers from 'cldr-numbers-full/main/de/numbers.json';
import deCurrencies from 'cldr-numbers-full/main/de/currencies.json';
import deGregorian from 'cldr-dates-full/main/de/ca-gregorian.json';
import deDateFields from 'cldr-dates-full/main/de/dateFields.json';
import deTimeZoneNames from 'cldr-dates-full/main/de/timeZoneNames.json';
export const loadCldr = (locale) => {
    const common = [likelySubtags, currencyData, weekData];
    if (locale === 'en') {
        load(...common, enNumbers, enCurrencies, enGregorian, enDateFields, enTimeZoneNames);
    }
    else {
        load(...common, deNumbers, deCurrencies, deGregorian, deDateFields, deTimeZoneNames);
    }
};
export const setDateLocale = (language) => {
    switch (language) {
        case 'de-DE': {
            dayjs.locale('de');
            break;
        }
        case 'en-EN': {
            dayjs.locale('en-gb');
            break;
        }
    }
};
