;
;
export const commonSettings = {
    dateFormat: 'DD MMM YYYY',
    dateTimeFormat: 'DD MMM YYYY HH:mm',
    serverDateFormat: 'YYYY-MM-DDTHH:mm:ss',
    dateI18nFormat: { day: 'numeric', month: 'long', year: 'numeric' },
    datePattern: '^\d{2}-[a-zA-Z]{3}-\d{4}$',
    emailPattern: '[ ]*[A-Za-z0-9._%\\-+]+@[a-zA-Z0-9+\\-_]+((\\.[a-zA-Z]{2,20}){1,2})[ ]*$',
    requestTimeout: 60000,
    toasterTimeout: 7000,
    maxInputLength: 100,
    maxTextareaLength: 300,
    maxLength10: 10,
    maxLength35: 35,
    maxLength50: 50,
    impressum: {
        companyName: 'Ratiodata SE',
        companyPhone: '+49 69 38076-0',
        companyEmail: 'info@ratiodata.de',
        companyHomepage: 'www.ratiodata.de',
        companyBoardOfDirectors: 'Martin Greiwe (${boardSpokesmanTranslation}), Klemens Baumgärtel, Dr. Michael Stanka',
        chairman: 'Jürgen Brinkmann',
        companyOffice: '60528 Frankfurt a. M.',
        companyAddress: {
            address: 'Gustav-Stresemann-Weg 29',
            zip: '48155',
            city: 'Münster',
        },
        commercialRegister: 'Amtsgericht Frankfurt a. M., Registernummer HRB 125160',
        taxIdNumber: 'DE214560149',
        companyGroupInfo: 'Systemhauspartner der Unternehmensgruppe Atruvia AG',
    },
    dataProtection: {
        email: 'datenschutz@ratiodata.de',
    },
};
export const ENV = !!window?.ENV ? {
    REACT_APP_API_URL: window.ENV['REACT_APP_API_URL'] || process.env.REACT_APP_API_URL,
    REACT_APP_STS_AUTHORITY: window.ENV['REACT_APP_STS_AUTHORITY'] || process.env.REACT_APP_STS_AUTHORITY,
    REACT_APP_CLIENT_ID: window.ENV['REACT_APP_CLIENT_ID'] || process.env.REACT_APP_CLIENT_ID,
    REACT_APP_URL: window.ENV['REACT_APP_URL'] || process.env.REACT_APP_URL,
    REACT_APP_RESPONSE_TYPE: window.ENV['REACT_APP_RESPONSE_TYPE'] || process.env.REACT_APP_RESPONSE_TYPE,
    REACT_APP_CLIENT_SCOPE: window.ENV['REACT_APP_CLIENT_SCOPE'] || process.env.REACT_APP_CLIENT_SCOPE,
    REACT_APP_SHORT_ENV: window.ENV['REACT_APP_SHORT_ENV'] || process.env.REACT_APP_SHORT_ENV,
    REACT_APP_RESOURCE: window.ENV['REACT_APP_RESOURCE'] || process.env.REACT_APP_RESOURCE,
} : {
    REACT_APP_API_URL: process.env.REACT_APP_API_URL,
    REACT_APP_STS_AUTHORITY: process.env.REACT_APP_STS_AUTHORITY,
    REACT_APP_CLIENT_ID: process.env.REACT_APP_CLIENT_ID,
    REACT_APP_URL: process.env.REACT_APP_URL,
    REACT_APP_RESPONSE_TYPE: process.env.REACT_APP_RESPONSE_TYPE,
    REACT_APP_CLIENT_SCOPE: process.env.REACT_APP_CLIENT_SCOPE,
    REACT_APP_SHORT_ENV: process.env.REACT_APP_SHORT_ENV,
    REACT_APP_RESOURCE: process.env.REACT_APP_RESOURCE,
};
