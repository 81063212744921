import { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { clearLocalStorage, getIsAuth } from '../helpers/Auth';
import { commonSettings } from '../settings';
import { usePageVisibility } from './PageVisibility';
const REACT_APP_API_URL = 'REACT_APP_API_URL';
const API_URL = process.env.REACT_APP_API_URL || window.ENV[REACT_APP_API_URL];
/**
* creates an instance of Axios with predefined settings
* baseURL: this sets the base URL for our API, whenever you make a request using axios instance,
* you only need to provide the endpoint path, and it will be appended to this URL.
* timeout: the maximum amount of time that the request should wait before timing out.
*/
const axiosInstance = axios.create({
    baseURL: `${API_URL}/api/v1/`,
    timeout: commonSettings.requestTimeout
});
export const useAxiosInstanceWithTokenInterceptors = (settings) => {
    const isPageVisible = usePageVisibility();
    const [isRefreshTokenNeeded, setIsRefreshTokenNeeded] = useState(false);
    const [refreshTokenResponse, setRefreshTokenResponse] = useState();
    const [inProgressCalls, setInProgressCalls] = useState(0);
    const isAuth = useRef(getIsAuth(settings.isAuthCookieKey));
    const forcedLoggedOut = useRef(!isAuth.current);
    const tokenSubscribers = useRef([]);
    const isRefreshingToken = useRef(false);
    /**
    * an interceptor is added that modifies the HTTP requests by:
    * setting a state that there are HTTP calls in progress
    * if the user was previously logged out, we no longer send the request.
    * also handle he cases where 'terms and conditions' are part of the request data
    */
    const requestHandlers = axiosInstance.interceptors.request.handlers;
    if (!requestHandlers || !requestHandlers.length) {
        axiosInstance.interceptors.request.use((requestConfig) => {
            setInProgressCalls(prevInProgressCalls => ++prevInProgressCalls);
            const noTokenNeeded = settings.noTokenNeededOn
                .filter((noTokenNeededForUrl) => requestConfig.url.includes(noTokenNeededForUrl));
            if (forcedLoggedOut.current) {
                if (noTokenNeeded.length === 0) {
                    throw new Error('LOGOUT');
                }
            }
            if (noTokenNeeded.length !== 0 && requestConfig?.data) {
                const data = typeof requestConfig.data === 'string' ? JSON.parse(requestConfig.data) : requestConfig.data;
                if (data && data.termsAndConditions !== undefined) {
                    throw new Error('termsAndConditions');
                }
            }
            return requestConfig;
        }, undefined);
    }
    /**
    * an interceptor is added that modifies the HTTP responses by:
    * decrementing the counter for ongoing HTTP calls.
    * we check if the response comes from  a login endpoint, if successfull -> we set that the user as authenticated.
    * if the response comes from a logout endpoint, we set user as unathenticate and we clear local storage.
    * for logout we clear the storage regardless if the response was sucessfull or not.
    */
    const responseHandlers = axiosInstance.interceptors.response.handlers;
    if (!responseHandlers || !responseHandlers.length) {
        axiosInstance.interceptors.response.use((response) => {
            setInProgressCalls(prevInProgressCalls => --prevInProgressCalls);
            if (response.config && response.config.url) {
                if (response.config.url.includes('cookies-authorization/login')) {
                    if (response.data?.isSuccess) {
                        setAuth(true);
                    }
                    forcedLoggedOut.current = false;
                }
                else if (response.config.url.includes('cookies-authorization/logout')) {
                    setAuth(false);
                    clearLocalStorage();
                }
            }
            return response;
        }, (error) => {
            if (error?.config?.url?.includes('cookies-authorization/logout')) {
                setAuth(false);
                clearLocalStorage();
            }
            return ErrorInterceptor(error);
        });
    }
    // when refreshTokenReponse changes, executes all callbacks subscribed to token renewal with the new token then clears the subscribers
    useEffect(() => {
        if (refreshTokenResponse) {
            setIsRefreshTokenNeeded(false);
            tokenSubscribers.current.map((subscribedCallback) => {
                return subscribedCallback.callback(refreshTokenResponse.error);
            });
            tokenSubscribers.current = [];
        }
    }, [refreshTokenResponse]);
    /**
    * checks if a token renewal is needed and there are no HTTP calls in progress,
    * when this is the case calls for the token renewal process
    * if it fails to refresh the token updates the token response with an error
    */
    useEffect(() => {
        if (!isRefreshTokenNeeded) {
            setRefreshTokenResponse(null);
        }
        else {
            if (!isRefreshingToken.current && inProgressCalls === 0) {
                isRefreshingToken.current = true;
                RenewToken()
                    .then(() => {
                    isRefreshingToken.current = false;
                    setRefreshTokenResponse({ error: null });
                })
                    .catch(() => {
                    isRefreshingToken.current = false;
                    setRefreshTokenResponse({ error: new Error('LOGOUT') });
                });
            }
        }
    }, [isRefreshTokenNeeded, inProgressCalls]);
    useEffect(() => {
        if (isPageVisible) {
            isAuth.current = getIsAuth(settings.isAuthCookieKey);
            if (!isAuth.current && settings.logout) {
                settings.logout();
            }
        }
    }, [isPageVisible]);
    /**
    * attempts to renew the user's access token.
    * if successful -> sets the authenticated state and updates the token
    * if unsuccessful -> removes the user from storage and foces logout
    */
    const RenewToken = () => {
        return new Promise((resolve, reject) => {
            const promise = axios.request({
                baseURL: `${API_URL}/api/v1/`,
                timeout: commonSettings.requestTimeout,
                method: 'POST',
                url: 'cookies-authorization/refresh-token',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                withCredentials: true
            })
                .then((res) => {
                return res.data;
            })
                .then((result) => {
                if (result && result.isSuccess) {
                    setAuth(true);
                    return result.token;
                }
                else {
                    forceLogout(result);
                    return null;
                }
            })
                .catch((result) => {
                forceLogout(result.response);
                return null;
            });
            promise.then(resolve, reject);
        });
    };
    // subscribes a callback for token renewal if not already subscribed. 
    const subscribeTokenRefresh = (subscribeCallback) => {
        const alreadySubscribed = tokenSubscribers.current.findIndex((subscriber) => subscriber.url === subscribeCallback.url);
        if (alreadySubscribed === -1) {
            tokenSubscribers.current.push(subscribeCallback);
        }
        setInProgressCalls(prevInProgressCalls => --prevInProgressCalls);
    };
    /**
    * creates and subscribes a callback for token renewal.
    * the callback sets the authorization header with the new token and retries the request with the original config
    * errors are returned that occured when the token was refreshed
    */
    const addTokenSubscriber = (requestConfig) => {
        return new Promise((resolve, reject) => {
            const subscribeCallback = {
                url: requestConfig.url,
                callback: (errRefreshing) => {
                    if (errRefreshing) {
                        return reject(errRefreshing);
                    }
                    requestConfig._retry = true;
                    return resolve(axiosInstance(requestConfig));
                }
            };
            subscribeTokenRefresh(subscribeCallback);
        });
    };
    const forceLogout = (response) => {
        forcedLoggedOut.current = true;
        const errorMessage = response
            ? JSON.stringify(response.data && response.data.messages ? response.data.messages : [])
            : undefined;
        // clear isAuth, then set Toaster if needed
        setAuth(false);
        clearLocalStorage(errorMessage);
        if (settings.logout) {
            settings.logout();
        }
        throw new Error('LOGOUT');
    };
    /**
    * an interceptor method for handling errors that occur during HTTP requests.
    * when tokens are not required - meaning the error most likely occured on the login, it rejects the call.
    ** call is rejected if the error is 403 and we force logout the user
    ** call is rejected when status is 401
    ** if there is no error in the configuration -> we reject the promise
    */
    const ErrorInterceptor = async (err) => {
        const onRequestFailedPromise = new Promise((resolve, reject) => {
            const rejectAndSubstractInProgressCall = (reason) => {
                setInProgressCalls(prevInProgressCalls => --prevInProgressCalls);
                return reject(reason);
            };
            if (err && err.config) {
                if (err.config._retry) {
                    return rejectAndSubstractInProgressCall(err);
                }
                const noTokenNeeded = settings.noTokenNeededOn.filter((noTokenNeededForUrl) => err.config.url.includes(noTokenNeededForUrl));
                if (!err.response || (noTokenNeeded && noTokenNeeded.length)) {
                    return rejectAndSubstractInProgressCall(err);
                }
                if (err.response.status === 403) {
                    forceLogout(err.response);
                }
                if (err.response.status !== 401) {
                    return rejectAndSubstractInProgressCall(err);
                }
                setIsRefreshTokenNeeded(true);
                return resolve(addTokenSubscriber(err.config));
            }
            return rejectAndSubstractInProgressCall(err);
        });
        return onRequestFailedPromise;
    };
    const setAuth = (isAuthParam) => {
        isAuth.current = isAuthParam;
    };
    return axiosInstance;
};
