import React, { Fragment } from 'react';
export function SectionTreeListComponent(props) {
    return (React.createElement(Fragment, null, props.list &&
        Object.keys(props.list).map((key) => (React.createElement(SectionTreeItemComponent, { ...props, key: key, itemKey: key, itemValue: props.list[key] })))));
}
;
export function SectionTreeItemComponent(props) {
    let currentLevelConfig = props.currentLevelConfig;
    if (!currentLevelConfig) {
        currentLevelConfig =
            props.levelStyles.length > props.level
                ? props.levelStyles[props.level]
                : props.levelStyles[props.levelStyles.length - 1];
    }
    const decorator = currentLevelConfig.decorator !== 'key' ? currentLevelConfig.decorator : `${props.itemKey}.`;
    let text = '';
    let nextLevelData = null;
    if (typeof props.itemValue === 'string') {
        text = props.itemValue;
    }
    else {
        text = props.itemValue?.title;
        nextLevelData = { ...props.itemValue };
        delete nextLevelData.title;
    }
    return (React.createElement(Fragment, null,
        text && (React.createElement("div", { className: currentLevelConfig.decorator === 'key' ? 'margin-top16' : '' },
            React.createElement("span", { className: currentLevelConfig.decoratorClassName }, decorator),
            React.createElement("span", { className: currentLevelConfig.textClassName }, text))),
        nextLevelData && (React.createElement(SectionTreeConditionalIndentationComponent, { ...props, level: props.level + 1, list: nextLevelData, minLevelToIndent: props.minLevelToIndent || 0 }))));
}
;
export function SectionTreeConditionalIndentationComponent(props) {
    return props.level <= props.minLevelToIndent ? (React.createElement(SectionTreeListComponent, { ...props })) : (React.createElement("div", { className: 'info-view' },
        React.createElement("span", { className: 'section-indent' }),
        React.createElement("div", null,
            React.createElement(SectionTreeListComponent, { ...props }))));
}
;
SectionTreeListComponent.defaultProps = {
    list: [],
    level: 0,
    minLevelToIndent: 0,
    levelStyles: [
        {
            decorator: 'key',
            decoratorClassName: 'section-number bold-text blue-text',
            textClassName: 'section-title bold-text blue-text',
        },
        {
            decorator: 'key',
            decoratorClassName: 'section-number bold-text',
            textClassName: 'section-text grey-text',
        },
        {
            decorator: '\u25CF',
            decoratorClassName: 'section-number',
            textClassName: 'section-text grey-text',
        },
        {
            decorator: '\u25E6',
            decoratorClassName: 'section-number',
            textClassName: 'section-text grey-text',
        },
    ],
};
