export var ProjectType;
(function (ProjectType) {
    ProjectType[ProjectType["AssetCheck"] = 1] = "AssetCheck";
    // tslint:disable-next-line: no-bitwise
    ProjectType[ProjectType["Rollout"] = 2] = "Rollout";
})(ProjectType || (ProjectType = {}));
export const initialProject = {
    id: '',
    name: '',
    number: '',
    company: '',
    location: '',
    rowVersion: '',
    rolloutStart: '',
    rolloutEnd: '',
    employeeDeadline: null,
    description: '',
    internalNotes: '',
    contactName: '',
    contactPhone: '',
    contactEmail: '',
    customerContactName: '',
    customerContactEmail: '',
    customerContactPhone: '',
    customerContactSecondaryPhone: '',
    projectType: ProjectType.Rollout,
    dataIntegration: false,
    automaticHardwareApproval: true,
    pmUsersWithAccess: []
};
export const initialEmployeeProject = {
    id: '',
    name: '',
    rowVersionEmployeeProject: '',
    personalInfoConfirmed: false,
    feedbackRequested: false,
    locationsPrivacyChangePermissions: 0,
    start: null,
    end: null,
    employeeProjectId: '',
    employeeDeadline: null,
    description: '',
    contactName: '',
    contactPhone: '',
    contactEmail: '',
    projectType: ProjectType.Rollout,
    assetCheckLocations: [],
    rolloutLocations: [],
    automaticHardwareApproval: false
};
