import React, { createContext, useReducer } from 'react'
import { IAction } from '@rollout/common/lib/models/apiTypes'
import { lessThanToday } from '@rollout/common/lib/helpers/FormatDate'

import messages_en from '../i18n/en-EN.json'
import messages_de from '../i18n/de-DE.json'
import { initialState, initialStoreContext } from './initialState'
import { IState, IStoreContext } from './types'

export const StateContext = createContext<IStoreContext>(initialStoreContext)

function reducer(state: IState, action: IAction): IState {
  switch (action.type) {
    case 'SET_AUTH':
      return { ...state, auth: { ...state.auth, ...action.payload } }
    case 'FETCH_PROJECT':
      return { ...state, project: action.payload, employeeDeadlinePassed: lessThanToday(action.payload.employeeDeadline) }
    case 'SET_IS_FEEDBACK_REQUESTED':
      return {...state, project: {...state.project, feedbackRequested: action.payload}}
    case 'FETCH_PROJECTS':
      return { ...state, projects: action.payload }
    case 'FETCH_EMPLOYEE':
      return {
        ...state,
        employee: action.payload,
        auth: {
          name: action.payload.name || action.payload.userName,
          email: action.payload.email,
        },
      }
    case 'SET_LOCALE':
      return { ...state, locale: action.payload }
    case 'LOGIN':
      return { ...state, auth: action.payload, isAuth: true }
    case 'IS_LOADING':
      return {
        ...state,
        isLoading: action.payload ? state.isLoading + 1 : state.isLoading - 1,
      }
    case 'HAS_ERROR':
      return { ...state, hasError: action.payload }
    case 'LOGOUT':
      return state.isAuth ? { ...initialState, isLoading: state.isLoading, isAuth: false } : { ...state }
    case 'SUPPORT_VISIBLE': {
      return { ...state, isSupportVisible: action.payload }
    }
    case 'MOBILE_LINK_VISIBLE': {
      return { ...state, isMobileLinkVisible: action.payload }
    }
    case 'END_USER_URL': {
      return { ...state, endUserUrl: action.payload }
    }
    default:
      return state
  }
}

export const Store = (props: any) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  const getLocale = () => {
    if (state.locale.code === 'de') {
      return messages_de
    }
    return messages_en
  }
  const messages = getLocale()

  const store: IStoreContext = {
    messages,
    state,
    dispatch,
  }
  return <StateContext.Provider value={store}>{props.children}</StateContext.Provider>
}
