import React, { Fragment, useContext } from 'react'
import { DataProtectionContent } from '@rollout/common/lib/blocks/DataProtectionContent'

import { StateContext } from 'store'
import { Footer } from 'components/Footer'
import OpenMobileApp from 'components/OpenMobileApp'
import { settings } from 'settings'

import '@rollout/common/src/scss/dataProtection.scss'

export const DataProtection = () => {
  const { state, messages } = useContext(StateContext)

  const openMobileApp = (): void => {
    window.location.href = `${settings.mobileAppUrlScheme}`
  }

  return (
    <Fragment>
      <OpenMobileApp actionTitle={messages.useApp} action={openMobileApp} />
      <DataProtectionContent 
        mainClassName={state.isMobileLinkVisible ? 'mobile-link-margin' : ''}
        messages={{
          appName: messages.appName,
          germany: messages.germany,
          rdPhone: messages.rdPhone,
          rdEmail: messages.rdEmail,
          dataProtection: {
            privacyPolicy: messages.dataProtection.privacyPolicy,
            dataProtectionResponsible: messages.dataProtection.dataProtectionResponsible,
            sections: {
              I: messages.dataProtection.sections.I,
              II: messages.dataProtection.sections.II,
              III: messages.dataProtection.sections.III,
            }
          }
        }}
      />
      <Footer />
    </Fragment>
  )
}
